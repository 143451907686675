import Home from "@/views/Home";
import LegalDocumentation from "@/views/solicitud/components/LegalDocumentation";
import toApp from "@/views/solicitud/components/toApp";
import {homeRoute} from "@/helper/homeRoute";
const home = [
    {
        path: '/',
        name: homeRoute.HOME,
        component: Home,
    },
    {
        name: homeRoute.TERM_AND_CONDITION,
        path: '/termsandconditions',
        component: LegalDocumentation,
        props: {legales: homeRoute.TERM_AND_CONDITION_CODE},
    },
    {
        name: homeRoute.TERM_AND_CONDITION,
        path: '/termsandconditionsprestix',
        component: LegalDocumentation,
        props: {legales: homeRoute.TERM_AND_CONDITION_PRESTIX_CODE},
    },
    {
        name: homeRoute.ADHESION_CONTRACT,
        path: '/adhesioncontract',
        component: LegalDocumentation,
        props: {legales: homeRoute.ADHESION_CONTRACT_CODE},
    },
    {
        name: homeRoute.PRIVATE_POLICY,
        path: '/privatepolicy',
        component: LegalDocumentation,
        props: {legales: homeRoute.PRIVATE_POLICY_CODE},
    },
    {
        name: homeRoute.PRIVATE_POLICY,
        path: '/privatepolicyprestix',
        component: LegalDocumentation,
        props: {legales: homeRoute.PRIVATE_POLICY_PRESTIX_CODE},
    },
    {
        name: homeRoute.INFORMATION_FINANCIAL,
        path: '/informationtofinancialusers',
        component: LegalDocumentation,
        props: {legales: homeRoute.INFORMATION_FINANCIAL_CODE},
    },
    {
        name: homeRoute.PERSONAL_LOAN,
        path: '/personalloans',
        component: LegalDocumentation,
        props: {legales: homeRoute.PERSONAL_LOAN_CODE},
    },
    {
        name: homeRoute.CONSUMER_DEFENSE,
        path: '/consumerdefense',
        component: LegalDocumentation,
        props: {legales: homeRoute.CONSUMER_DEFENSE_CODE},
    },
    {
        name: homeRoute.SEND_TO_APP,
        path: '/descargaapp',
        component: toApp,
    }
]

export default home